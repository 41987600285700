.questionnaire-container {
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.questionnaire-container .slider-numbers {
  display: none;
}


.questionnaire-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
}

.questionnaire-categories-title {
  font-size: 48px;
  margin-top: 48px;
  margin-bottom: 75px;
  color: var(--primary);
  text-align: center;
}

.questionnaire-categories-entries {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.questionnaire-categories-entry {
  padding: 20px;
  font-size: 30px;
  border: 1px solid var(--black);
  cursor: pointer;
  background-color: transparent;
}

.questionnaire-categories-entry.active {
  padding: 20px;
  border-color: var(--primary);
  color: var(--primary);
}


.questionnaire-categories-finished {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.category-intro {
  margin-top: 50px;
  text-align: center;
}


.category-title { 
  font-size: 25px;
  color: var(--accent);
  font-weight: 700;
}

.grey-area {
  background-color: #f9f6f8;
}


.extra-info {
  position: fixed;
  width: 100%;
  height: 110vh;
  background:white;
}


.extra-info-width {
  margin-left: auto;
  margin-right: auto;
  max-width:700px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 100px;
}

.extra-info .small-button {
  margin: auto;
  display: block;
  margin-top: 20px;
}

.info-icon {
  width: 100px;
}


.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  top: 100%; /* Adjust the position based on your layout */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

/* Optional: Add arrow to the tooltip */
.tooltip::after {
  content: '';
  position: absolute;
  top: -5px; /* Adjust based on the size of the arrow */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.category-content {
  opacity: 0;
  margin-top: -20px;
  transform: translateY(20px);
  animation: slideUpFadeIn 0.5s ease-out forwards; /* Plays immediately on load */
}

.category-selection {
  text-align: center;
  margin: 20px;
}

.subtext {
  font-size: 10px;
  opacity: 0.8;
}


@keyframes slideUpFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (min-width: 751px) {

.intro-text {
  border-top: 1px solid #eee;
  background-color: #f3e6d8;
  padding: 20px;
  max-width: 400px;
  margin:auto;
  box-shadow: -1px 12px 50px 10px hsla(0,0%,49%,.1);
}

.partner-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-logo {
  width: 100px;
  margin: 20px;
}

}

@media (max-width: 750px) {


  .category-intro {
    margin-top: 0;
  }
  
  .questionnaire-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .category-title { 
    font-size: 20px;
    color: var(--accent);
    font-weight: bold;
  }

  .questionnaire-categories-title {
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .questionnaire-categories-entries {
    margin-bottom: 25px;
  }

  .questionnaire-categories-finished {
    margin-bottom: 25px;
    margin-top:20px;
    font-size: 18px;
  }

  .questionnaire-bottom {
    margin-bottom: 25px;
    margin-top: 15px;
    gap: 10px;
  }

  .select-button-children {
    font-size: 12px;
  }


  .intro-text {
    border-top: 1px solid #eee;
    background-color: #f3e6d8;
    padding: 20px;
  }

  .partner-logos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partner-logo {
    width: 15vw;
    margin: 20px;
  }


  .button-group {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }


}


