@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Space+Grotesk');

@font-face {
  font-family: "poppins-extralight";
  src: url("../public/poppins/Poppins-ExtraLight.ttf");}

:root {
  --primary: #00acc3;
  --primary-darker: #0094a7;
  --primary-50: #00acc1;
  --secondary: #bf3e1e;
  --secondary-50: #a33216;
  --third: #ed9912;
  --third-50: #d08711;
  --tertiary: #540b0e;

  --light: #f8f2e5;

  --accent: #335c67;
  --accent-50: rgba(51, 92, 103, 0.5);
  --accent-light: #8fb9c5;

  --green: rgba(23, 131, 82, 1);
  --green-darker: rgb(11, 72, 45);

  --black: #0d0d0d;
  --gray: #7f7f7f;
  --white: #ffffff;
  --white-50: #333;
  --white-80: #ddd;

  --border-gray: rgba(0, 0, 0, 0.35);
}

button {
  user-select: none;
}

a {
  user-select: none;
}

.button-group {
  display: flex;
  justify-content: space-around;
  text-align: center;
  gap: 20px;
  margin-bottom: 10px;
  align-items: stretch;
}

.selection-button {
  padding: 20px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  flex: 1; /* Make the buttons flexible */
  user-select: none;
}

.red-button {
  background-color: var(--secondary);
  color: white;
}

.red-button.active {
  background-color: var(--secondary) !important;
}


.yellow-button {
  background-color: #e99000;;
  color: white;
}

.yellow-button.active {
  background-color: #e99000 !important;
}

.dark-yellow-button {
  background-color: #d86d00;
  color: white;
}

.dark-yellow-button.active {
  background-color: #d86d00 !important;
}


@media (max-width: 760px) {
  body {

    margin: 0;
    padding:0;
    font-family: poppins-extralight, poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
  }


.menu-item {
  display:none;
}

.menu-item a {
  text-decoration: none;
  color:black;
}

.mia-button-yellow {
  margin-top:30px;
  font-family: "space grotesk", sans-serif;
  background-color: hsl(46, 100%, 50%);
  border: 1px black solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  width: 100%;
  padding: 8px 0px;
  text-align: center;
  text-decoration: none;
  color:black;
}


.mia-button-blue {
  margin-top:30px;
  font-family: "space grotesk", sans-serif;
  background-color: #71cbff;
  border: 1px black solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  width: 100%;
  padding: 8px 0px;
  text-align: center;
  text-decoration: none;
  color:black;
}

.wrapper {
  padding: 0 20px 0 20px;
}

.my-account-wrapper {
  padding: 0 35px 0 35px;
}

}

@media (min-width: 761px) {

  body {
    margin: 0;
    padding:0;
    font-family: poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
  }

.menu {
  float:right;
  display: flex;
  margin-bottom: 50px;
}

.menu-item {
  font-family: poppins-extralight, poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 30px;
  margin-top:10px;
}

.menu-item a {
  text-decoration: none;
  color:black;
}

.mia-button-yellow {
  margin-left:50px;
  font-family: "space grotesk", sans-serif;
  background-color: hsl(46, 100%, 50%);
  border: 1px black solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  min-width: 180px;
  padding: 8px 0px;
  text-align: center;
  text-decoration: none;
  color:black;
}


.mia-button-blue {
  margin-left:50px;
  font-family: "space grotesk", sans-serif;
  background-color: #71cbff;
  border: 1px black solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  max-width: inherit;
  min-width: 180px;
  padding: 8px 0px;
  text-align: center;
  text-decoration: none;
  color:black;
}

.wrapper {
  padding: 20px 80px 0 80px;
}


.my-account-wrapper {
  padding: 0 35px 0 35px;
}

}

.logo {
  font-family: lobster, cursive;
  font-size: 35px;
  font-weight:700;
}

.logo a {
  text-decoration: none;
  color: black;
}

html {
  height: 100%;
}

textarea {
  font-family: "Helvetica Neue", sans-serif;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-end {
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.flex-grow {
  flex-grow: 1;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-baseline {
  align-items: baseline;
}

.m-4 {
  margin: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-40 {
  margin-right: 10rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.pointer {
  cursor: pointer;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.align-items-end {
  align-items: end;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: bold;
}

.container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

#root {
  flex-direction: column;
  height: 100vh;
}

.title {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 0.75rem;
}

.large-button {
  text-decoration: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: var(--black);
  font-size: 24px;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.large-button.active {
  border: 1px solid var(--primary);
}

.large-button:hover {
  border: 1px solid var(--primary);
}

.small-button {
  text-decoration: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white);;
  font-size: 14px;
  padding: 8px 14px;
  gap: 20px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  min-width: 120px;
  text-align: center;
  max-width: inherit;
  border-radius: 50px;
}

.small-button:hover {
  background-color: rgba(127, 127, 127, 0.25);
}


.number-button {
  text-decoration: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white);;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  max-width: inherit;
  border-radius: 5px;
  margin-left: 5px;
}

.number-button:hover {
  background-color: rgba(127, 127, 127, 0.25);
}

.light-button {
  background-color: var(--primary-darker);
}

.light-button:hover {
  background-color: var(--primary-darker);
}

.lighter-button {
  background-color: var(--primary-50);
}

.lighter-button:hover {
  background-color: var(--primary-darker);
}


.white-button {
  background-color: white;
  border: 1px solid #bdbdbd;
  color: var(--secondary);
}

.white-button:hover {
  background-color: hsl(0deg 0% 95.26%);
  border: 1px solid #989898;
  color: #9e292b;
}

.dark-button {
  background-color: var(--secondary);
}

.dark-button:hover {
  background-color: var(--secondary-50);
}


.medium-dark-button {
  background-color: var(--third);
}

.medium-dark-button:hover {
  background-color: var(--third-50);
}

.accent-button {
  background-color: var(--accent);
}

.accent-button:hover {
  background-color: var(--accent-50);
}

input {
  border-radius: 6px!important;
  height: 54px;
  padding: 9px 14px 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7e6e5;
  color: #222;
  display: block;
  transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  min-width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

input:focus {
  outline:none;
  border-color: #2697d8!important;
}

textarea {
  border-radius: 6px!important;
  padding: 9px 14px 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e6e7;
  color: #222;
  display: block;
  transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  min-width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

textarea:focus {
  outline:none;
  border-color: #2697d8!important;
}


@media (max-width: 750px) {
  .large-button {
    font-size: 18px;
    padding: 12px 18px;
  }

  .small-button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .desktop {
    display: none;
  }
  input {
    width: 100%;
    min-width: 300px;
  }
  textarea {
    width: 100%;
    min-width: 300px;
  }
}

@media (min-width: 750px) {
  .mobile {
    display: none;
  }

  .container {
    width:90%;
  }

}

.dark-footer {
  background-color:black;
  color: #aaa;
  text-align: center;
  padding: 50px;
  font-size: smaller;
}

.dark-footer a {
  color: white;
  text-decoration: none;
  font-size: larger;
}

