.slider-question {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slider-question-slider {
  width: 75%;
}

.slider-question .slider-number {
  font-size: 24px;
  margin-bottom: 10px;
}

@media (max-width: 750px) {
  .slider-question-slider {
    width: 95%;
  }

  .slider-question .slider-number {
    font-size: 16px;
  }
}
