.container {
  font-family: 'Poppins', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
  background-color: #fff;
  position: relative;
}

.logo {
  font-size: 28px;
  font-weight: bold;
}

.provider-image {
  max-width: 100%;
}

.provider-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px; /* Rounded shape */
  background-color: #00acc1; /* Blueish greenish button background */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.provider-button:hover {
  background-color: #0092ab; /* Blue background color */
}
.main {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #fff; /* Set the main background to white */
  max-width: 1300px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f0f8ff; /* Light blue background */
  padding: 50px;
  width: 100%;
  position: relative;
  gap: 100px;
}

.text-section {
  max-width: 35%;
  order: 1; /* Default order for desktop */
}

.text-section h1 {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.info-list {
  list-style: none;
  padding: 0;
  max-width: 40%;
  margin: 20px 0 0 35px;
  cursor: pointer;
}

.info-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.info-list .icon {
  font-size: 24px;
  color: rgb(36, 147, 132);
  margin-right: 20px;
  margin-top: -5px;
}

.info-list .text p {
  margin: 0;
  font-weight: bold;
}

.info-list .text span {
  display: block;
  margin-top: 5px;
  color: #555;
  font-size: 14px;
}

.test-button {
  margin-top: 40px;
  padding: 16px 30px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 100px; /* Make the button rounded */
  border: 1px solid black; /* Remove the default border */
  color: white; /* Set the text color */
  background-color: #e54b1c;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  position: relative; /* For positioning the pseudo-element */
  margin-left: 0; /* Adjust this value to align with the text */
  font-weight: bold;
}

.test-button::after {
  content: '\2192'; /* Unicode for the right arrow */
  font-size: 20px; /* Match the font size of the button text */
  margin-left: 10px; /* Space between text and arrow */
  transition: margin-left 0.3s ease; /* Smooth transition for hover effect */
}

.test-button:hover {
  background-color: #ce3000; /* Darken the background color on hover */
  color: white;
}

.test-button:hover::after {
  margin-left: 20px; /* Move the arrow to the right on hover */
}

.image-section {
  width: 50%;
  order: 2; /* Default order for desktop */
  position: relative;
  max-height: 100px;
}

.image-section img {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
}

.paralax-home {
  transform: translateY(0);
  transition: transform 0.5s ease-out;
}

.hulpverlener {
  margin: 10vw -20px 0 -20px; /* To align correctly with the previous section */
  position: relative;
  padding: 80px 100px;
  background-color: #fbf5ee;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
  max-width:1200px;
}

.hulpverlener-bigtext {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  max-width: 40%;
}

.hulpverlener-text {
  max-width: 60%;
}

.hulpverlener img {
  max-width: 100%;
  height: auto;
}


.hulpverlener-solutions {
  margin: 0 -20px 0 -20px; /* To align correctly with the previous section */
  position: relative;
  padding: 50px 100px;
  background-color: #f3e6d8;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width:1200px;
  gap:100px;
}


.hulpverlener-solutions .solutions{
  position: relative;
  display: block;
}

.hulpverlener-solutions-title {
  margin:0;
  flex: 0 0 20%;
}

.important-text {
  font-weight: bold;
  background-color: #f3e6d8;
  padding: 5px 10px 5px 10px;
  line-height: 35px;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  width: 100%;
}

.subscribe-button {
  margin-top: 20px;
  padding: 16px 30px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 100px; /* Make the button rounded */
  border: 1px solid black; /* Add border */
  color: white; /* Set the text color */
  background-color: #00acc1; /* Blueish greenish button background */
  transition: background-color 0.3s ease, color 0.3s ease, margin-left 0.3s ease; /* Smooth transition for hover effect */
  position: relative; /* For positioning the pseudo-element */
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.subscribe-button::after {
  content: '\2192'; /* Unicode for the right arrow */
  font-size: 20px; /* Match the font size of the button text */
  margin-left: 10px; /* Space between text and arrow */
  transition: margin-left 0.3s ease; /* Smooth transition for hover effect */
}

.subscribe-button:hover {
  background-color: #0092ab; /* Blue background color */
  color: white;
}

.subscribe-button:hover::after {
  margin-left: 20px; /* Move the arrow to the right on hover */
}


.features-intro {
  text-align: center;
  max-width: 700px;
  margin: 50px auto 0 auto;
}

/* New section styles */
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  position: relative;
  padding: 50px 0 50px 0;
  gap: 80px;
  max-width: 1300px;
  z-index:-1;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  flex: 0 0 17%; /* Adjust this value to control column width */
  margin: 20px;
  text-align: center;
}

.feature img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add space between the image and the title */
}

.feature h4 {
  margin: 10px 0; /* Add margin to space out the titles */
}

.feature p {
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.sponsors {
  display: flex;
  justify-content: center;
  align-items: top;
  background-color: #f0f8ff;
  padding: 20px 50px 50px 50px;
  gap: 50px;
  max-width: 1200px;
}

.sponsors-images {
  width: 100%;
  gap: 0; /* Adjust gap between images if needed */
}

.sponsors img {
  max-width: 100%;
  height: auto;
  margin: 0 20px 0 0;
}

.vragen-homepage {
  margin-left: 30px;
}

.vragen-homepage a {
  color: #00acc1;
  text-decoration: none;
}

.vragen-homepage a:hover {
  text-decoration: underline;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {

  .main {
    flex-direction: column;
    align-items: center;
    padding: 0 20px 20px 20px;
  }

  .content-wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .text-section {
    max-width: 100%;
    margin: -100px 0 0 0;
    text-align: center;
    order: 2; /* Text section comes after image on mobile */
  }

  .text-section h1 {
    font-size: 28px;
  }

  .info-list {
    max-width: 100%;
    margin: 20px 0;
  }

  .info-list li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .info-list .icon {
    margin-bottom: 10px;
  }

  .test-button {
    padding: 12px 20px;
    font-size: 18px;
    margin-left: 0; /* Reset margin for mobile */
    margin-top: 20px;
    display: block;
    margin: 20px auto; /* Center the button on mobile */
  }

  .hulpverlener-solutions-title {
    margin: 0 0 20px 0;
  }

  .image-section {
    width: 100%;
    order: 1; /* Image section comes first on mobile */
    margin-bottom: 20px;
    position: static; /* Ensure it positions correctly */
    max-height: 1000px;
  }

  .image-section img {
    position: static; /* Ensure it positions correctly */
    width: 100%;
    height: auto;
  }


  .hulpverlener {
    margin: 140vw 0 0 0;
    flex-direction: column;
    position: relative;
    padding: 30px;
    background-color: #fbf5ee;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width:1200px;
    gap:0;
  }


  .hulpverlener-bigtext {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    max-width: 100%;
    text-align: center;
  }
  
  .hulpverlener-text {
    max-width: 100%;
    text-align: center;
  }


  .hulpverlener-solutions {
    margin: 0;
    flex-direction: column;
    position: relative;
    padding: 30px;
    background-color: #f3e6d8;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width:1200px;
    gap:0;
  }
  .features-intro {
    margin: 50px 0;
  }


  .features {
    flex-direction: column;
    padding: 20px;
  }

  .feature {
    flex: 1 1 100%;
    margin: 10px 0;
  }


  .sponsors {
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: column;
    background-color: #f0f8ff;
    padding: 30px;
    gap: 0;
  }

  .sponsors-images {
    width: 100%;
    gap: 0; /* Adjust gap between images if needed */
  }

  .vragen-homepage {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

}
