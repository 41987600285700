.nowork-checkbox {
  width: 20px !important;
  height:20px !important;
  min-width: 20px !important;
}

.nowork-checkbox-container label {
  margin-left: 10px;
  font-weight: 800;
}

.nowork-checkbox:checked + label::before {
  background-color: red;
}

.nowork-checkbox-container {
  display: flex;
  align-items: center; /* Align items vertically */
}


.nowork-checkbox {
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #999;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.nowork-checkbox:checked {
  /* Change the border color when checked */
  border-color: #3375b7;
}

.nowork-checkbox::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  position: relative; /* Add this line */
  top: -7px; /* Adjust the vertical position */
  left: -7px; /* Adjust the horizontal position */
}

.nowork-checkbox:checked::before {
  /* Show a checkmark when checked */
  content: "\2713";
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  color: #3375b7;
  position: relative; /* Add this line */
  top: -7px; /* Adjust the vertical position */
  left: -7px; /* Adjust the horizontal position */
}