
/* Main Section */
.main-info {
  margin-top: 20px;
  display: block;
  justify-content: center;
  padding: 0;
  background-color: #fff; /* Set the main background to white */
  max-width: 1300px;
}

.intro-section {
  text-align: center;
  padding: 50px;
  background-color: #f0f8ff; /* Light blue */
}


.intro-section-in-article {
  text-align: center;
  padding: 50px;
  background-color: #f0f8ff; /* Light blue */
}

.intro-section h1 {
  font-size: 36px;
  font-weight: bold;
}

.intro-section p {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}


.intro-section-in-article h1 {
  font-size: 36px;
  font-weight: bold;
}

.intro-section-in-article p {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}

/* Info Sections */
.info-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}

.info-section .left-column {
  width: 30%;
  font-size: 24px;
  font-weight: bold;
}

.info-section .right-column {
  width: 65%;
  font-size: 16px;
}

.info-section .right-column p {
  margin-bottom: 20px;
}

/* Read More Button */
.read-more-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 50px; /* Rounded button */
  border: none;
  background-color: #00acc1; /* Blueish greenish button background */
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #0092ab; /* Darker background on hover */
}

.informatie-image-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}


.main-article {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  background-color: #fff;
  max-width: 1300px;
  margin: 0 auto;
}


.article-section {
  margin-bottom: 40px;
  margin-left: 0; /* Default for mobile */
}

.article-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-section p, .article-section ul {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.article-section a {
  color: #00acc1;
  text-decoration: none;
}

.article-section a:hover {
  text-decoration: underline;
}

.content-wrapper-article {
  width: 100%;
}

.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #00acc1;
  text-decoration: none;
  font-size: 18px;
}

.back-button:hover .back-button-text {
  text-decoration: underline;
}

.back-button::before {
  content: '\2190'; /* Unicode for the left arrow */
  margin-right: 10px;
  font-size: 20px;
}

.info-image {
  max-width: 100%;
}

@media (min-width: 768px) {
  .article-section {
    margin-left: 25%; /* 25% left margin for desktop */
  }
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {

  .main-info {
    margin-top: 0;
  }

  .intro-section-in-article {
    display: none;
  }

  .info-section {
    flex-direction: column;
    align-items: center;
  }

  .info-section .left-column,
  .info-section .right-column {
    width: 100%;
    text-align: center;
  }

  .info-section .left-column {
    margin-bottom: 20px;
  }


  .main-article {
    flex-direction: column;
    align-items: center;
  }

  .content-wrapper-article {
    padding: 0;
  }

  .article-section h2 {
    font-size: 24px;
  }

  .article-section p, .article-section ul {
    font-size: 14px;
  }
}