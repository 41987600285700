
/* Footer styles */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 50px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
}
.footer-column {
  flex: 1 1 30%;
}

.footer-column h4 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.footer-social img {
  width: 30px;
  height: 30px;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .footer {
    padding: 30px 30px 50px 30px;
  }
}

