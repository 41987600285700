.image-input-wrapper {
  width: 200px;
  height: 200px;
  border: solid 1px var(--white-50);
  object-fit: cover;
  position: relative;
  background-color: white;
}

.image-input {
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-input-wrapper-plus {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  pointer-events: none;
}

.image-input-wrapper-plus img {
  object-fit: cover;
}

.image-input-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  padding: 0 20px;
}
