.medium-card {
  display: flex;
  background-color: white;
  color: black;
  margin-left: -80px;
  margin-right: -80px;
  padding: 40px 80px 40px 80px;
  gap: 40px;
  cursor: pointer;
}

.medium-hover-behaviour:hover {
  background-color: #f5f2f4;
}

.medium-card-image-container {
  flex-shrink: 0; /* Prevent image from shrinking */
  overflow: hidden; /* Ensures the image maintains the circular mask */
  background-color: #fff; 
  width: 150px; /* Set the size of the image */
  padding:20px;
  margin-bottom: 40px;
  margin-top: 0;
}

.medium-card-image {
  width: 100%; /* Set the size of the image */
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without being stretched */
  background-color: white;
  border: 1px solid #ddd;
}

.medium-card-text {
  width: 100%;
}

.medium-card-text h2 {
  font-size: 20px;
  margin-bottom: 0;
}

.medium-card-name {
  font-weight: bold;
  font-size: 32px;
}




@media (max-width: 750px) {

  .medium-card {
    margin-left: -20px;
    margin-right: -20px;
    padding: 40px 20px 40px 20px;
  }
  .medium-card-image-container {
    margin: 0 auto 20px auto;
  }

  .medium-card-text {
    text-align: center;
  }

  .medium-card-name {
    font-size: 24px;
  }



}



.background-darker {
  background-color: #f9f6f8;
}


.image-placeholder {
  width: 190px;
  height: 190px;
}


