.progress-bar {
  padding-bottom: 30px;
}
.progress-bar-container {
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 0;
  align-items: center; /* Centers children vertically */
  height: 12px;
}

.progress-bar-progress {
  flex-grow: 1;
  background-color: var(--gray);
  height: 5px;
  cursor: pointer;
  max-width: 200px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.progress-bar-passed {
  background-color: var(--secondary);
  background-image: linear-gradient(to right, var(--secondary) 50%, rgb(219, 40, 0) 75%, var(--secondary));
  background-size: 200% 100%;
  animation: slideWave 0.6s forwards, pulsateHeight 0.3s forwards;
}

@keyframes slideWave {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes pulsateHeight {
  0% { height: 5px; }
  50% { height: 12px; } /* Temporary height increase */
  100% { height: 5px; }
}

.progress-bar-categories {
  font-weight: bold;
  font-size: 30px;
  height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 24px;
  justify-content: center;
  color: var(--secondary);
  width:100%;
}

@media (min-width:751px) {
  .progress-bar-container {
    width:98%;
  }
}

@media (max-width: 750px) {
  .progress-bar {
    padding-bottom: 12px;
  }

  .progress-bar-categories {
    margin-bottom: 0;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .progress-bar-container {
    gap: 5px;
  }
}
