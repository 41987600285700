.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 0;
  background-color: #fff;
  position: relative;
}

.logo {
  font-size: 28px;
  font-weight: bold;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #000;
}

.provider-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px; /* Rounded shape */
  background-color: #00acc1; /* Blueish green background color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.provider-button:hover {
  background-color: #008b9b; /* Darker blueish green on hover */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 10; /* Ensure it stays on top */
}

.hamburger span {
  height: 2px;
  width: 25px;
  background: black;
  margin-bottom: 4px;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
  }

  .nav {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center; /* Center align the nav items */
    margin-top: 20px;
    border: 1px solid #eee;
    border-radius: 20px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transition for animation */
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .nav.open {
    max-height: 500px; /* Adjust based on content height */
    opacity: 1;
    padding: 20px 0 20px 0;
  }

  .nav a, .provider-button {
    margin: 10px 0;
  }

  .hamburger {
    display: flex;
  }
}
