.results-title {
  font-size: 50px;
}

.results-bottom {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
}

.next-button {
  margin-left: 5px;
}

.small-link {
  text-align: center;
  display:block;
  text-decoration: underline;
  cursor: pointer;
  color:black;
}

.dialog-container {
  position: relative; /* Ensure relative positioning for absolute positioning of buttons */
}

.filter-label {
  margin-bottom: 16px;
  margin-top: 16px;
  font-weight: bold;
  font-size: 16px;
}

.results-input {
  width: 100px;
}

.filters-closed {
  margin-top: 20px;    
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  color: black;
}

.filters {
  border-radius: 5px;
  padding: 20px;
  color: black;
  margin-bottom: 10px;
}

.centered-text {
  text-align: center;
}

.filtering-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  column-gap: 20px;
}

.filtering-left {
  flex-grow:1;
}

.filtering-right {

  flex-basis: 150px; /* Set fixed size for column2 */
  flex-shrink: 0; /* Prevent column2 from shrinking */
}

.filter-on-city {
  padding: 16px 16px;
  min-width: 150px;
}

.other-filters {
  margin-top: 10px;
  padding: 8px 16px;
  min-width: 150px;
}

.extra-categories {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: -80px;
  margin-right: -80px;
  padding: 10px 80px 10px 80px;
  background-color: #fDfAfC;
}

.tab-list-right {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #eee;
}

.filter-buttons {
  font-family: poppins-extralight, poppins, sans-serif;
  position: absolute;
  bottom: 0; /* Adjust as needed */
  left: 0; /* Adjust as needed */
  right: 0; /* Adjust as needed */
  background-color: #eee;
  padding: 20px 20px 20px 20px;
  display: flex;
  justify-content: end;
  border-top: 1px solid #eee;
  z-index: 2000;
}

.lighter {
  color: #777;
}

.email-form {
  padding:20px;
}

.results-intro-text {
  max-width: 800px;
  margin-bottom: 40px;
}

.suggestion {
  border-top: 5px solid #f2dbc3;
  background-color: #f3e6d8;
  padding: 10px 80px;
}

.suggestion-bottom {
  border-bottom: 5px solid #f2dbc3;
}


.tab-list-right {
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown {
  position: relative;
}
.dropdown-button {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  cursor: pointer;
}
.dropdown-content {
  display: block;
  position: absolute;
  right: 0; /* Ensure dropdown aligns to the right of the button */
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
}
.dropdown-content div {
  padding: 12px 16px;
  text-align: left;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #eaeaea;
}


.select-button-group-results {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  border-bottom: 10px solid #eee;
}

.tab-selection-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 4px 4px 0 0;
  flex: 1; /* Make the buttons flexible */
}



@media (max-width: 750px) {


  .select-button-group-results {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    border-bottom: 10px solid #eee;
  }


  .extra-categories {
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 10px 20px;
  }

  .results-title {
    font-size: 32px;
  }

  .filters .filter-buttons button {
    margin-left:10px;
  }

  .filters .filter-buttons button:last-child {
    margin-bottom: 0;
  }
  .location-input {
    min-width: 100%;
  
  }
  .suggestion {
    margin: 0 -20px;
    padding: 10px 20px;
  }
  .suggestion-bottom {
    margin: 0 -20px;
  }

  .results-title-mobile {
    display: block;
    font-size: 20px;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  .filter-on-city {
    padding: 16px 16px;
    min-width: 80px;
    font-size: 14px;
  }

  .other-filters {
    margin-top: 10px;
    padding: 8px 16px;
    min-width: 80px;
  }

  .filtering-right {
    flex-basis: 80px;
    flex-shrink: 0;
  }

}

@media (min-width: 751px) {

  .select-button-group-results {
    align-items: end;
  }


  .wider {
    margin-left: -80px;
    margin-right: -80px;
  }
  
  .filters .filter-buttons {
    float:right;
    display: flex;
  }
  
  .filters .filter-buttons button {
    margin-left: 10px;
  }

  .results-title-mobile {
    display: none;
  }

}

.dialog-input {
  width: calc(100% - 10px);
}
