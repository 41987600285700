.title {
  margin-bottom:0;
}

.subtitle {
  margin-bottom:20px;
}

.my-account {
  background-color: white;
  color: #333;
  padding-bottom: 50px;
  box-sizing: border-box;
  position: relative;
}

.my-account-extra-padding {
  padding: 20px;
  height: 100%;
}

.my-account-dialog-close-button {
  bottom: 20px;
}

.next-to-eachother {
  display: grid;
  grid-template-columns: 1fr 1fr; /* On desktop, two columns */
  column-gap: 40px;
}

.chosen-address {
  background-color: #eee;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #eee;
}

.my-account .select-button-answer.active {
  background-color: var(--primary-darker);
  color: white;
  border: 1px solid black;
}


.my-account .settings-checkbox-container {
  margin-top: 32px;
}

.my-account textarea {
  height: 120px;
}

.my-account h1 {
  margin-top: 25px;
  font-size: 1.6em;
}

.my-account h2 {
  font-size: 1.2em;
}

.my-account .warning {
  background-color: #f0f0f0;
  display:flex;
  padding: 10px;
  font-weight: 800;
  border-radius: 6px;
  color: #777;
  justify-content: space-between; /* Align items to the start and end of the container */
  column-gap: 20px;
  margin-bottom: 20px;
}

.certification {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 20px;
}

.remove-cert-button {
  float: right;
  cursor: pointer;
  color: white;
  border: 1px solid var(--primary-50);
  border-radius: 5px;
  padding-top: 5px;
  background-color: var(--primary-50);
}

.dont-forget-to-save {
  color: #777;
  font-size: 12px;
}

.my-account-methods {
  margin-top: 0;
  background-color: var(--primary);
}

.my-account-label {
  margin-bottom: 16px;
  margin-top: 32px;
}

.my-account-save {
  z-index: 9000;
  margin-bottom:50px;
}

.my-account-save button {
  width: 8em;
  float:right;
  margin-left:20px;
  font-family: "poppins-extralight";
}


.my-account-save button.dark-button {
  width: 75%;
  background-color: var(--secondary);
}

.my-account-save button:hover.dark-button {
  background-color: var(--secondary);
}
.my-account .checkboxes-container {
  flex-direction: row;
  justify-content: space-between; 
}


.save-dialog-footer {
  padding: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.error-message {
  color: white;
  background-color: rgb(160, 0, 0);
  font-size: 12px;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-weight: 800;
}

.preview-button {
  padding: 5px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 800;
  cursor: pointer;
  margin-left: 20px;
}


.offline-button {
  padding: 5px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 800;
  cursor: pointer;
  margin-left: 10px;
  background-color: #bf3e1e;
}


.offline-button:hover {
  background-color: #9a2b0f;
}

.preview-button:hover {
  color: white;
}

.title-for-mia-praktijk {
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 30px;
  margin-bottom: -10px;
  font-weight: 100;
}
.subtitle-for-mia-praktijk {
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  margin-bottom: 35px;
} 

.small-button {
  border-radius: 10px;
}

.small-top-buttons-right {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 761px) {

  .small-top-buttons-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .my-account-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }


  .preview-button {
    height: 30px;
    width: 220px;
    margin-left: 0;
  }

  .offline-button {
    height: 30px;
    width: 220px;
    margin-left:0;
    margin-top: 10px;
  }

  }
  
  @media (max-width: 760px) {
    
  .preview-button {
    margin-left:0;
    vertical-align: top;
  }

  .my-account-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .chosen-address {
    background-color: #eee;
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #eee;
  }

  .my-account {
    margin-top: 0;
    width: 100%;
  }

  .my-account-save {
    padding: 20px 20px 20px 20px;
  }

  .my-account-save button {
    width: 8em;
  }

  .my-account .checkboxes-container {
    display: flex;
    flex-direction: column;
  }
  .next-to-eachother {
    grid-template-columns: 1fr; /* On mobile, one column */
  }

  .footer-my-account {
    background: black;
    color: white;
    min-height: 300px;
  }

  .small-top-buttons-right {
    margin-top: 20px;
  }

  .my-account .warning {
    flex-direction: column;
  }
  
}
