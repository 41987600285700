.select-button-answer {
  font-size: 16px;
  padding: 10px 15px;
  font-weight: 400;
  border-radius: 6px;
  font-family: 'Poppins', sans-serif;
  transition: transform 0.1s ease, background-color 0.2s, color 0.2s, border-color 0.2s; /* Smooth transitions */
  cursor: pointer;
  outline: none;
  border: 1px solid #ccc; /* Visible border for initial state */
}

.select-button-answer:active {
  transform: scale(0.95); /* Press effect */
}

.select-button-answer.active {
  background-color: var(--primary);
  color: white;
  border: 1px solid black;
}

.select-button-answer:hover {
  color: var(--primary);
  border-color: var(--primary); /* Border color change on hover */
}

.select-button-answer.active:hover {
  color: white;
  border-color: black; /* Keeps border black on hover when active */
}

.select-button-checkbox {
  height: 16px;
  min-width: 16px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s ease; /* Smooth transition for border color */
}

.select-button-children {
  text-align: left;
}

.select-button-answer.active .select-button-checkbox {
  border-color: var(--white);
  background-color: var(--white);
}

.select-button-answer img {
  width: 75%;
}

@media (max-width: 750px) {
  .select-button-answer {
    font-size: 12px; /* Slightly smaller font for mobile */
    padding: 10px 20px;
  }

  .select-button-checkbox {
    height: 14px;
    min-width: 14px;
  }
}
