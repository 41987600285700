
.progress-container {
  position: relative;
  margin: 10px 20px;
  height: 30px;
  background-color: #ddd;
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.progress-gauge {
  height: 30px;
  background-color: #00acc1;
  width: 0%; /* Initial width */
  border-radius: 15px;
  transition: width 1s ease-in-out;
}

.percentage-label {
  position: absolute;
  text-align: center;
  top: 5px;
  color: white;
  margin: 0 auto;
  width: 100%;

}
