.provider-card {
  display: flex;
  background-color: white;
  color: black;
  margin-left: -80px;
  margin-right: -80px;
  padding: 40px 80px 40px 80px;
  gap: 80px;
  cursor: pointer;
}

.provider-card-text {
  width: 100%;
  display: flex;
  flex-direction: column;
}



.provider-card-name {
  font-weight: bold;
  font-size: 32px;
}

.provider-card-subtitle {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0.5rem;
}


.provider-card-image-container {
  flex-shrink: 0; /* Prevent image from shrinking */
  overflow: hidden; /* Ensures the image maintains the circular mask */
  background-color: #fff; 
  width: 300px; /* Set the size of the image */
  height: 300px;
  padding:20px;
  margin-bottom: 40px;
  margin-top: 0;
}

.provider-card-image {
  width: 100%; /* Set the size of the image */
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without being stretched */
  background-color: white;
}

.provider-card .why-button {
  margin-bottom: 20px !important;
  max-width: 200px !important;
}

.provider-card .contact-button {
  max-width: 200px !important;
}
.provider-hover-behaviour:hover {
  background-color: #f5f2f4;
}

.provider-card-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
}

.provider-card-info span {
  padding-top: 0.25rem;
}

.provider-card-info a {
  color: var(--tertiary);
}

.expertise {
  background-color: #e8e8e8;
}

.contact {
  box-shadow: 0px -1px 0px 4px hsla(0,0%,49%,.1);
}

.provider-card-right {
  margin: 0 0 0 auto;
}

.provider-card-text h2 {
  font-size: 20px;
  margin-bottom: 0;
}

.provider-card-buttons {
  display: contents;
}

.provider-card-text {
  width: 100%;
}


@media (max-width: 750px) {

  .provider-card {
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 40px 20px;
    text-align: center;
}

  .provider-card-image-container {
    width: 100%;
    padding:0;
    margin-top: 0;
  }

  .provider-card-name {
    font-size: 24px;
  }

  .provider-card-buttons {
    display: block;
  }


  .provider-card-contact {
    justify-content: center;
  }


  .provider-card-right {
    max-width: 100%;
  }
  
}


.background-darker {
  background-color: #f9f6f8;
}

