.progress-display {
  border-radius: 15px;
  animation: movefromtop 1s forwards;
}


.matches-info {
  padding: 20px 0;
  color: #777;
  font-size: 14px;
  background-color: #f9f6f8;
  border-radius: 15px;
}

@keyframes movefromtop {
  from { margin-top: -100px; }
  to { margin-top: 0; }
}

.bubble-container {
  margin: 10px auto 0 auto;
  position: relative;
  width: 250px;
  height: 220px; /* Adjust based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures nothing goes outside the container */
}

.bubble {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}


.line {
  position: absolute;
  top: 0;
  width: 2px;
  background-color: black;
  transform: translateX(-50%);
}

@keyframes swingAndBob1 {
  0%, 100% {
    transform: scale(0.3)  rotate(-7deg) translateY(-7px);
  }
  10% {
    transform: scale(1)  rotate(0deg) translateY(0px);
  }
  25% {
    transform: scale(1)  rotate(5deg) translateY(8px);
  }
  40% {
    transform: scale(1)  rotate(0deg) translateY(0px);
  }
  55% {
    transform: scale(1)  rotate(0deg) translateY(0px);
  }
  70% {
    transform: scale(1)  rotate(6deg) translateY(7px);
  }
  90% {
    transform: scale(1)  rotate(0deg) translateY(0px);
  }
}

@keyframes swingAndBob2 {
  0%, 100% {
    transform: scale(0.3) rotate(0deg) translateY(7px);
  }
  10% {
    transform: scale(1) rotate(-5deg) translateY(0px);
  }
  30% {
    transform: scale(1) rotate(0deg) translateY(8px);
  }
  45% {
    transform: scale(1) rotate(3deg) translateY(0px);
  }
  65% {
    transform: scale(1) rotate(-5deg) translateY(0px);
  }
  80% {
    transform: scale(1) rotate(0deg) translateY(5px);
  }
  90% {
    transform: scale(1) rotate(3deg) translateY(0px);
  }
}


@keyframes moveStrong {
  0% {
    transform: translateY(-20px);
    transform: translateX(-20px);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
  }
}

@keyframes moveVeryGood {
  0% {
    transform: translateY(-20px);
    transform: translateX(20px);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
  }
}


@keyframes moveGood {
  0% {
    transform: translateY(-10px);
    transform: translateX(-15px);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
  }
}

@keyframes moveReasonable {
  0% {
    transform: translateY(-10px);
    transform: translateX(15px);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
  }
}


/* Colors and additional styles for specific bubbles */

.strong-match {
  position: absolute;
  text-align: center; /* Center text below the bubble */
  display: flex;
  flex-direction: column; /* Stack bubble and text vertically */
  align-items: center; /* Center-align the items */
  top: 20px;
  left: 10px;
  animation: moveStrong 1s forwards;
  font-size: 12px;
  color: #00acc1;
}

.strong-match .bubble {
  position: relative;
  background-color: #00acc1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  animation: swingAndBob1 15s ease-in-out infinite;
}

.very-good-match {
  position: absolute;
  text-align: center; /* Center text below the bubble */
  display: flex;
  flex-direction: column; /* Stack bubble and text vertically */
  align-items: center; /* Center-align the items */
  top: 40px;
  left: 110px;
  animation: moveVeryGood 1s forwards;
  font-size: 12px;
  color: #74aeb3;
}

.very-good-match .bubble {
  position: relative;
  background-color: #74aeb3;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  animation: swingAndBob2 15s ease-in-out infinite;
}

.good-match {
  position: absolute;
  text-align: center; /* Center text below the bubble */
  display: flex;
  flex-direction: column; /* Stack bubble and text vertically */
  align-items: center; /* Center-align the items */
  top: 90px;
  left: 40px;
  animation: moveGood 1s forwards;
  font-size: 12px;
  color: #fba44d;
}

.good-match .bubble {
  position: relative;
  background-color: #fba44d;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  animation: swingAndBob2 15s ease-in-out infinite;
}

.reasonable-match  {
  position: absolute;
  text-align: center; /* Center text below the bubble */
  display: flex;
  flex-direction: column; /* Stack bubble and text vertically */
  align-items: center; /* Center-align the items */
  top: 100px;
  left: 120px;
  animation: moveReasonable 1s forwards;
  font-size: 12px;
  color: #eb8e90;
}
.reasonable-match .bubble {
  position: relative;
  background-color: #eb8e90;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  animation: swingAndBob1 15s ease-in-out infinite;
}

