
button {

  font-family: 'POPPINS-extralight';
}

.dialog-overlay {
  z-index: 20;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dialog-body {
  background-color: var(--white);
  position: absolute;
  width: 100%;
  height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  box-shadow: 0 10px 10px -5px rgba(var(--gray), 0.04),
  0 20px 25px -5px rgba(var(--gray), 0.1);
  overflow: hidden;
}

.dialog-content {
  height: 100%;
  padding: 1em;
  overflow: auto;
}

.dialog-body.dialog-padding {
  padding: 0 2rem 2rem 2rem;
}

.dialog-body.is-small {
  max-width: 676px;
}

.dialog-body.is-msmall {
  max-width: 500px;
}

.dialog-body.is-xsmall {
  max-width: 400px;
}

.dialog-body.is-large {
  max-width: 1031px;
}

.dialog-body.is-wide {
  max-width: 900px;
}

.dialog-body.is-slide {
  max-width: 1400px;
}

.dialog-header {
  display: flex;
  z-index: 1;
  background: #ccc;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 20px 35px;
}

.dialog-title {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 0;
  color: var(--white);
}

.dialog-open {
  overflow: hidden;
}

.dialog-footer {
  z-index: 1;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--primary);
}

.dialog-label {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 20px;
}

.dialog-cancel {
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  transition: all 100ms;
}

.dialog-cancel:hover {
  color: var(--black);
}

.dialog-body .close-button {
  color: var(--white);
  transition: all 100ms;
  cursor: pointer;
  position: absolute;
  right: 12px;
  width: 23px;
  height: 23px;
  font-size: 22px;
}

.dialog-body .close-button:hover {
  color: var(--black);
}


@media screen and (max-width: 768px) {
  .dialog-body {
    /* max-width: 80% !important; */
    height: 100vh;
    left:0;
    top:0;
    transform: translate(0,0);
    width: 100vw;
  }
}
