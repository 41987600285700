.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--secondary);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--secondary);
  cursor: pointer;
}

.slider-container {
  width: 100%;
}

.slider-info-text {
  text-align: center;
  font-size: 16px;
  margin-bottom: 25px;
}

.slider-numbers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}

.lefttext-righttext {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
}

.slider-numbers > div {
  cursor: pointer;
}

.slider-number {
  font-weight: bold;
  width: 25px;
  text-align: center;
}

.slider-number.active {
  color: var(--secondary);
}



@media (max-width: 750px) {
  .slider-info-text {
    text-align: center;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .lefttext-righttext {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
  }
}