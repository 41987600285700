.base-question {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.base-question-title {
  font-size: 20px;
  color: var(--accent);
  text-align: center;
  background-color: var(--white);
  padding: 0 10px 30px 10px;
  font-weight: bold;
}

.base-question-switch {
  flex-grow: 1;
}

.base-question-explanation {
  font-size: 12px;
  color: var(--accent);
  text-align: center;
  background-color: #f9f6f8;
  padding: 10px 10px 10px 10px;
  border: 1px solid #eee;
  margin-top: 20px;
}

@media (max-width: 750px) {
  .base-question-title {
    font-size: 14px;
    padding: 5px 10px 15px 10px;
  }
  .base-question-explanation {
    font-size: 10px;
  }
}
