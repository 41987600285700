.add-certification {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.add-certification-second-row {
  margin-top: 20px;
}
.add-certification-last-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}