.top-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hulpverlener-image {
  display: block;
  width: 40%;
  height: auto;
  object-fit: cover;
  position: relative;
  margin: 0 auto -30px auto;
}

.gratis-image {
  display: block;
  position: relative;
  margin: 0 0 -100px -300px;
}


.main-hulpverlener {
  display: flex;
  justify-content: center;
  padding: 0;
  background-color: #fff; /* Set the main background to white */
  max-width: 1300px;
  margin-top: 20px;
}

.promointro {
  text-align: center;
  width: 50%;
  margin: 50px auto 0 auto;
}

.promo-section {
  padding-bottom: 20px;
}

.promocontainer {
  position: relative;
  background-color: black;
  color: white;
  padding: 0 0 0 0;
  margin: 0 0 50px 0; 
  border-radius: 20px;
  overflow: hidden;
}

.promocontainer video {
  margin-bottom: -10px;
}

.promobutton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  border-radius: 10px;
  padding: 5px 10px;
  border: 0;
  opacity: 0.8;
  cursor: pointer;
}

.content-wrapper-hulpverlener {
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px 20px 50px 20px;
  width: 100%;
  position: relative;
  gap: 100px;
  border-radius: 20px;
}

.text-section-hulpverlener {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 20px 100px;
}


.important-text-hulpverlener {
  font-weight: bold;
  background-color: #d9ebf7;
  padding: 5px 10px 5px 10px;
  line-height: 35px;
}


.hulpverlener-solutions-praktijk {
  border-radius: 0 0 20px 20px;
  margin: 0 150px 0 150px; /* To align correctly with the previous section */
  position: relative;
  padding: 50px 50px;
  background-color: #f4e8dc;
  display: flex;
  align-items: flex-start;
  max-width:900px;
  gap:100px;
}


.hulpverlener-solutions-profiel {
  border-radius: 20px 20px 0 0;
  margin: 0 150px 0 150px; /* To align correctly with the previous section */
  position: relative;
  padding: 50px 50px;
  background-color: #f7eee4;
  display: flex;
  align-items: flex-start;
  max-width:900px;
  gap:100px;
}

.paralax {
  margin-top: 50px;
  transition: transform 0.5s ease-out;
}


.hulpverlener-solutions-profiel .hulpverlener-solutions-title {
  flex: 0 0 35%;
}

.hulpverlener-solutions-praktijk .hulpverlener-solutions-title {
  flex: 0 0 35%;
  margin-top: -20px;
}


.hulpverlener-solutions-profiel .hulpverlener-solutions-title .the-title {

  background: #f4e8dc;
  padding: 10px 40px;
  margin-top: -12px;
  z-index:2;
  position: relative;
}


.hulpverlener-solutions-praktijk .hulpverlener-solutions-title .the-title {

  background: #f7eee4;
  padding: 10px 40px;
  margin-top: -12px;
  z-index:2;
  position: relative;
}


.hulpverlener-solutions-praktijk .hulpverlener-solutions-title .the-title {
  margin-top: -45px;
}

.hulpverlener-solutions-title .image-holder {
  z-index: -1;
}


.hulpverlener-solutions-praktijk .solutions{
  position: relative;
  display: block;
}

/* Panel container */
.panel-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 1300px;
  margin: 30px auto 0 auto;
}

/* Common panel styles */
.panel {
  padding: 50px;
  flex: 1;
  max-width: 45%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panel-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.panel-subtitle {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.panel-content {
  flex: 1;
}

.panel-content p {
  font-size: 16px;
  margin-bottom: 10px;
}

.panel-footer {
  text-align: center;
  padding: 10px 10px 30px 10px;
  margin: 0 -50px -50px -50px;
}

.panel-footer p {
  margin: 0;
}

/* Panel button with arrow */
.panel-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 50px; /* Rounded button */
  border: 1px solid black;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, margin-left 0.3s ease; /* Include arrow animation */
  margin-top: 20px;
  position: relative; /* For positioning the pseudo-element */
}

.panel-button::after {
  content: '\2192'; /* Unicode for the right arrow */
  font-size: 20px; /* Match the font size of the button text */
  margin-left: 10px; /* Space between text and arrow */
  transition: margin-left 0.3s ease; /* Smooth transition for hover effect */
}

.panel-button:hover {
  background-color: #555; /* Darker background on hover */
}

.panel-button:hover::after {
  margin-left: 20px; /* Move the arrow to the right on hover */
}

.miaprofiel-image {
  transform: rotate(21deg);
  scale: 1.3;

}

.miapraktijk-image {
  transform: rotate(35deg);
  scale: 1.2;
  clip-path: inset(0 0 40px 0); /* Clips 40px from the bottom */
  margin-bottom: -40px; /* Moves the image up 40px */
}

/* Specific styles for Mia Starter and Mia Standaard */
.mia-starter {
  background-color: #e9f6f7; /* Lighter greenish background */
  scale: 0.94;
}

.mia-starter .panel-footer {
  background-color: #b2ebf2; /* Light greenish footer background */
}

.mia-starter .panel-button {
  background-color: #00acc1; /* Greenish button background */
}

.mia-starter .panel-button:hover {
  background-color: #0092ab; /* Darker background on hover */
}



.mia-standaard {
  background-color: #e0f7f9; /* Darker greenish background */
  transition: scale 0.3s ease;
}


.mia-standaard:hover {
  scale:1.05;
}

.mia-standaard .panel-footer {
  background-color: #b2ebf2; /* Light greenish footer background */
}

.mia-standaard .panel-button {
  background-color: #00acc1; /* Greenish button background */
}

.mia-standaard .panel-button:hover {
  background-color: #0092ab; /* Darker background on hover */
}

.provider-image-wrapper {
  display: flex;
  justify-content: end;
}

.provider-image-in-hulpverlener {
  max-width: 340px;
}

.package-title {
  text-align: center;
}

.diagonal-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 600px;
  background-color: rgb(36 161 170);
}

.left-side, .right-side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-side {
  background:#f0f8ff;
  clip-path: polygon(0 -1px, 99% -1px, -1px 94%, -1px -1px);
}

.left-side h2 {
  color: #e74c3c;
}

.left-side-content {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  margin-right: 50%;
}

.subtitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.right-side {
  background:#f0f8ff;
  clip-path: polygon(102% 0, 101% 100%, 1% 103%);
  justify-content: end;
}

.right-side h2 {
  color: #00acc1;
}

.right-side-content {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  margin-left: 50%;
}

.hulpverlener-buy-section {
  margin-top: 250px;
  padding: 50px;
  background-color: #fbf5ee;
  box-shadow: 0 -10px 100px 0 rgba(0, 0, 0, 0.1);
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .promodesktop {
    display: none;
  }
  .paralax {
    margin-top: 200px;
  }

  .panel {
    padding:30px;
  }
  .panel-footer {
    text-align: center;
    padding: 10px 10px 30px 10px;
    margin: 0 -30px -30px -30px;
  }

  .hulpverlener-image {
    width: 100%;
    margin: 0 0 -10px 40px;
  }
  .promobutton {
    top: 50%;  /* Centers vertically */
    left: 50%; /* Centers horizontally */
    transform: translate(-50%, -50%); /* Adjust position to truly center based on button dimensions */

  }

.text-section-hulpverlener {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0 0;
}
.paralax {
  background-color: white;
}

.promointro {
  display: none;
}

.package-title {
  padding: 0 30px 0 30px;
}

  .hulpverlener-solutions-praktijk {
    margin: 0;
    flex-direction: column;
    position: relative;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width:1200px;
    gap:0;
  }


  .hulpverlener-solutions-profiel {
    margin: 0;
    flex-direction: column;
    position: relative;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width:1200px;
    gap:0;
  }


  .hulpverlener-solutions-title .the-title {
    margin-bottom: 20px;
  }

  .panel-container {
    flex-direction: column;
    align-items: center;
    gap:30px;
  }

  .panel {
    max-width: 100%;
  }

  .provider-image-in-hulpverlener {
    max-width: 100%;
  }


  .gratis-image {
    max-width: 100%;
    display: none;
    position: relative;
    z-index: -2;
    margin: 0 0 -20px -300px;
  }

  .mia-starter {
    scale: 1;
  }

  .main-hulpverlener {
    margin-top: 0;
  }


  .diagonal-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: auto;
    background-color: transparent;
    margin-bottom: 450px;
  }
  
  .left-side, .right-side {
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
  }

.left-side {
  background: none;
  clip-path: none;
}

.left-side h2 {
  color: #00acc1;
}

.left-side-content {
  margin-left: 0;
  margin-right: 0;
  padding: 20px;
}

.subtitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.right-side {
  background: none;
  clip-path: none;
}

.right-side h2 {
  color: #e74c3c;
}

.right-side-content {
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
  padding: 20px;
}


.hulpverlener-buy-section {
  margin-top: 0;
  padding: 20px;
  background-color: #fbf5ee;
  box-shadow: none;
}
  
}