.multiple-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.multiple-choice-answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}