
.match-gauge-div  {
  margin-top: -40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  background-color: #eee;
  padding: 20px 0 25px 20px;
  border-top: 1px solid #ccc;
}

.match-gauge-div .first-item {
  flex-grow: 1;        /* Allows this item to grow and take up most of the space */
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.match-gauge-div .second-item {
  flex-grow: 0;        /* Prevents this item from growing, takes only needed space */
  margin-left: auto;   /* Pushes the item to the right side */
}


.gauge-popup {
  position: absolute;
  transform: translate(-20px, -190px);
  background-color: white;
  padding: 30px;
  z-index: 100;
  opacity: 0.9;
  width: 280px;
  
}

.popup-content {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gauge-popup .gauge {
  width: 270px !important;
  height: 150px !important;
}

@media (max-width: 750px) {

.match-gauge-div {
  margin-top: -40px;
  margin-bottom: 20px;
}
}