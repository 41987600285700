/* ContactFormPage.css */

.contact-form-page {
  margin-bottom: 50px;
}

.contactform {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bottom-animation {
  margin-bottom: -7px;
}

@media (min-width: 768px) {
  .contactform {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px; 
  }

  .contactform div {
    display: flex;
    flex-direction: column;
  }

  .contactform textarea {
    grid-column: span 2;
  }

  .contactform .contact-send-button {
    grid-column: span 2;
    justify-self: start;
  }
}
