.settings-select {
  flex-grow: 1;
}

.settings-select-answers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.settings-select-button-checkbox {
  min-height: 15px;
  min-width: 15px;
  border: 1px solid var(--white-80);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.select-button-answer.active .settings-select-button-checkbox {
  background-color: var(--white);
}

.settings-large-button {
  text-decoration: none;
  background-color: transparent;
  font-size: 14px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.settings-large-button.light {
  border: 1px solid var(--white-80);
  color: var(--white-50);
}

.settings-large-button.active {
  color: var(--white);
}

.settings-large-button.dark {
  border: 1px solid var(--accent);
  color: var(--accent);
}

.settings-select-no-box {
  flex-direction: row;
}

@media (max-width: 700px) {
  .settings-select-answers {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .settings-select-answers {
    grid-template-columns: 1fr;
  }
}
