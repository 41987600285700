
.waiting-gauge-div  {
  margin-top: -40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  background-color: #eee;
  padding: 20px 0 25px 20px;
  border-top: 1px solid #ccc;
}


.waiting-gauge-div .first-item {
  flex-grow: 1;        /* Allows this item to grow and take up most of the space */
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.waiting-gauge-div .second-item {
  flex-grow: 0;        /* Prevents this item from growing, takes only needed space */
  margin-left: auto;   /* Pushes the item to the right side */
}


.waiting-gauge {
  margin-top:4px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.waiting-gauge-fill {
  width: 0;
  height: 100%;
  border-radius: 5px;
  transition: width 1s ease;
}

.waiting-gauge-text {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}

.waiting-gauge-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  transform: translate(-20px, -150px);
  background-color: white;
  padding: 30px;
  z-index: 100;
  opacity: 0.9;
  width: 280px;
  align-items: center;
  
}


@media (max-width: 750px) {

  .waiting-gauge-div {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  }