
.setting-name {
  border-radius: 4px;
  padding:14px;
}

.setting-more-button {
  border-radius: 4px;
  padding:14px;
  cursor: pointer;
}
