.settings-checkbox {
  padding: 0;
  border: none;
}

.settings-select-button-checkbox {
  width: 20px;
  height: 20px;
}

.settings-checkbox-label {
  font-size: 16px;
  cursor: pointer;
}

.settings-checkbox-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 12px;
}

.settings-checkbox .select-button-answer.active {
  background-color: var(--primary);
  color: black;
  border: 1px solid black;
}

@media (max-width: 750px) {
  .settings-checkbox-container {
    flex-direction: row;
    gap: 8px;
  }
}
