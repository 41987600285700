.auto-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.auto-complete-answers {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.auto-complete-answer {    
  background-color: white;
  padding: 10px;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  cursor:pointer;
}

.active {    
  background-color: var(--primary);
}