.provider-details-title {
  font-size: 50px;
}

.provider-details-bottom {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.provider-details-bottom .small-button {
  background-color: var(--primary);
}

.provider-details-bottom .small-button:hover {
  background-color: var(--secondary);
}

.details-working-hours {
  padding: 0 20px 0 5px;
}

.small-link {
  text-align: center;
  display:block;
  text-decoration: underline;
  cursor: pointer;
  color:black;
}

.provider-details-input {
  width: 100px;
}

.centered-text {
  text-align: center;
}

.main-content {
  background-color: #fff; /* White background for content */
}

.introduction-section,
.details-section,
.additional-information {
  margin-bottom: 24px;
}

.introduction-section {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for desktop */
  grid-gap: 16px;
}

.details-section {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for desktop */
  grid-gap: 16px;
}


.session-info {
  padding: 16px 20px 30px 20px;
  background-color: #f9f6f8; /* Slightly darker grey for section backgrounds */
}

.practical-information {
  margin: 0 0 0 -80px;
  padding: 16px 20px 30px 80px;
  background-color: #f9f6f8;
}

.support-section {
  margin: 0 -80px 0 0;
  padding: 16px 80px 30px 20px;
  background-color: #f9f6f8;
}


.consult-info {
  margin: 0 -80px 0 -80px;
  padding: 16px 80px 30px 80px;
  background-color: #f9f6f8; /* Slightly darker grey for section backgrounds */
}

.slider-numbers {
  display: none;
}

.match-info {
  margin: -40px -80px 0 -80px;
  padding: 0 80px 50px 80px;
  background-color: #f9f6f8; /* Slightly darker grey for section backgrounds */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Two columns for desktop */
  grid-column-gap: 80px;
}

.match-info-white {
  padding: 16px 0 50px 0;
  background-color: white; /* Slightly darker grey for section backgrounds */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Two columns for desktop */
  grid-column-gap: 80px;
}

.similar-section {
  margin: 0 -30px 0 -30px;
  padding: 16px 30px 30px 30px;
  background-color: #f9f6f8; /* Slightly darker grey for section backgrounds */
}
.similar-info {
  padding: 0 0 20px 0;
  background-color: #f9f6f8; /* Slightly darker grey for section backgrounds */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Two columns for desktop */
  grid-column-gap: 80px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Make sure it takes the full width */
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 15%;
}

.back-link {
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: auto;
}

.professional-info h1 {
  margin: 10px 0 20px;
  color: #000; /* Darker text for the name for emphasis */
}

.professional-info p {
  margin: 4px 0 10px; /* Spacing between lines */
}


.personal-info h1 {
  margin: 10px 0 20px;
  color: #000; /* Darker text for the name for emphasis */
}

.personal-info p {
  margin: 4px 0 10px; /* Spacing between lines */
}


.professional-info .address {
  font-size: 20px;
}

.contact-button {
  margin-top: 20px;
  padding: 8px 16px;
  min-width: 200px;
}

.why-button {
  margin-top: 10px;
  padding: 8px 16px;
  min-width: 200px;
  margin-bottom: 60px;
}

.methods-container {
  display: flex;
  flex-wrap: wrap; /* Ensures the divs wrap if they don't fit in one line */
  gap: 10px; /* Optional: adds some space between the divs */
}

.match-info .lefttext-righttext {
  display: none;
}

.profile-image-container {
  flex-shrink: 0; /* Prevent image from shrinking */
  overflow: hidden; /* Ensures the image maintains the circular mask */
  background-color: #f9f6f8; 
  width: 300px; /* Set the size of the image */
  height: 300px;
  padding:20px;
  margin-bottom: 40px;
  margin-top: 60px;
}

.profile-image {
  width: 100%; /* Set the size of the image */
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without being stretched */
}

.why {
  padding-left: 20px;
  padding-right: 20px;
}

.similar-label {
  border-radius: 4px;
  background-color: white;
  padding:14px;
  margin-bottom: 10px;
}

.show-link {
  cursor: pointer;
  text-decoration: underline;
}


.bottom-animation {
  margin-bottom: -7px;
}

/* Responsive adjustments as needed */
@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    align-items: center;
    margin-right: 0;
  }

  .contact-button {
    margin-top: 16px; /* Add space between the button and the professional info on small screens */
  }

  .profile-image-container {
    margin-top: 16px;
  }

  .details-section {
    grid-template-columns: 1fr; /* Stack columns on mobile */
  }

  .match-info {
    grid-template-columns: 1fr; /* Stack columns on mobile */
  }

  .profile-image-container {
    flex-shrink: 0; /* Prevent image from shrinking */
    overflow: hidden; /* Ensures the image maintains the circular mask */
    background-color: #f9f6f8;
    width: 100%; 
    padding:20px;
  }

  .profile-image {
    width: 100%; 
    object-fit: cover; /* Ensures the image covers the area without being stretched */
  }

}


@media (max-width: 768px) {
  .provider-details-title {
    font-size: 32px;
  }


  .profile-image-container {
    margin-bottom: 0;
  }

  .details-working-hours {
    padding: 0;
  }


}

.dialog-input {
  width: calc(100% - 10px);
}

/* Change the background color and font size of autocomplete items */
.pac-item {
  font-size: 14px !important;
  padding: 10px;
  font-family: 'poppins-extralight';
  background-color: #eee !important;
}

/* Change the hover state color */
.pac-item:hover {
  background-color: #ddd !important;
}


