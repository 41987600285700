
.method-name {
  border-radius: 4px;
  background-color: #f9f6f8;
  padding:14px;
  cursor:default;
}

.method-name * {
  pointer-events: none;
}

.method-more-button {
  border-radius: 4px;
  padding:14px;
  cursor: pointer;
}

.method-popup-info {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.05);
  padding: 10px;
  z-index: 1000;
  max-width: 300px;
  border-radius: 5px;
}

.method-popup-close-button {
  background: none;
  border: none;
  font-weight: bold;
  float: right;
  cursor: pointer;
}

.method-popup-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .method-popup-info {
    left: 20px !important; /* Force the left position to 20px */
    right: auto; /* Reset any right positioning */
  }
}
