.contactform > div {
  margin-bottom: 10px;
}


.contactform-error {
  color: red;
  font-size: 12px;
}


@media (max-width: 768px) {
  .contactform > div {
    margin-bottom: 10px;
    max-width: 100%;
  }
  .contact-send-button {
    width: 100%;
  }
}
