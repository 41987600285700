.single-choice {
  height: 100%;
}

.single-choice-answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.single-choice-answer {
  font-size: 16px;
  padding: 10px 15px;
  font-weight: 400;
  justify-content: center;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  transition: transform 0.1s ease, background-color 0.2s, color 0.2s; /* Added transition for transform */
  cursor: pointer;
  outline: none;
  border: 1px solid #ccc; /* Initial less prominent border color */
}

.single-choice-answer:active {
  transform: scale(0.95); /* Scales down the button when clicked */
}

.single-choice-answer.active {
  background-color: var(--primary);
  color: white;
  border: 1px solid black;
}

.single-choice-answer:hover {
  color: var(--primary);
}

.single-choice-answer.active:hover {
  color: white;
}

@media (max-width: 750px) {
  .single-choice-answer {
    font-size: 12px;
    padding: 10px 15px;
  }
}
